exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2018-02-18-identyfikacja-uzytkownika-na-podstawie-odcisku-przegladarki-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/2018-02-18-identyfikacja-uzytkownika-na-podstawie-odcisku-przegladarki/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2018-02-18-identyfikacja-uzytkownika-na-podstawie-odcisku-przegladarki-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2018-03-05-bitcoin-origine-et-fonctionnement-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/2018-03-05-bitcoin-origine-et-fonctionnement/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2018-03-05-bitcoin-origine-et-fonctionnement-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2018-04-29-publishing-packages-to-npm-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/2018-04-29-publishing-packages-to-npm/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2018-04-29-publishing-packages-to-npm-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2018-05-13-exporting-flow-definitions-to-npm-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/2018-05-13-exporting-flow-definitions-to-npm/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2018-05-13-exporting-flow-definitions-to-npm-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2019-01-05-creating-offline-first-vue-apps-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/2019-01-05-creating-offline-first-vue-apps/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2019-01-05-creating-offline-first-vue-apps-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2019-03-01-enterprise-scale-react-redux-project-architecture-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/2019-03-01-enterprise-scale-react-redux-project-architecture/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2019-03-01-enterprise-scale-react-redux-project-architecture-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2019-04-19-lazy-loading-and-code-splitting-in-react-apps-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/2019-04-19-lazy-loading-and-code-splitting-in-react-apps/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2019-04-19-lazy-loading-and-code-splitting-in-react-apps-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2021-12-13-accessible-css-that-respects-user-choices-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/2021-12-13-accessible-css-that-respects-user-choices/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2021-12-13-accessible-css-that-respects-user-choices-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2022-06-11-case-study-tilt-copters-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/2022-06-11-case-study-tilt-copters/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2022-06-11-case-study-tilt-copters-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2022-06-27-case-study-filler-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/2022-06-27-case-study-filler/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2022-06-27-case-study-filler-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-11-19-api-response-validation-with-zod-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/2023-11-19-api-response-validation-with-zod/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-11-19-api-response-validation-with-zod-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-compound-components-in-react-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/compound-components-in-react/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-compound-components-in-react-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-pitfalls-of-barrel-files-in-javascript-modules-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/pitfalls-of-barrel-files-in-javascript-modules/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-pitfalls-of-barrel-files-in-javascript-modules-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-ultimate-gatsby-seo-guide-how-to-automatically-generate-thumbnails-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/ultimate-gatsby-seo-guide/how-to-automatically-generate-thumbnails.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-ultimate-gatsby-seo-guide-how-to-automatically-generate-thumbnails-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-ultimate-gatsby-seo-guide-how-to-create-a-sitemap-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/ultimate-gatsby-seo-guide/how-to-create-a-sitemap.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-ultimate-gatsby-seo-guide-how-to-create-a-sitemap-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-ultimate-gatsby-seo-guide-how-to-create-an-seo-component-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/ultimate-gatsby-seo-guide/how-to-create-an-seo-component.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-ultimate-gatsby-seo-guide-how-to-create-an-seo-component-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-ultimate-gatsby-seo-guide-how-to-structure-data-for-rich-snippets-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/bartozzz.github.io/bartozzz.github.io/content/blog/ultimate-gatsby-seo-guide/how-to-structure-data-for-rich-snippets.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-ultimate-gatsby-seo-guide-how-to-structure-data-for-rich-snippets-md" */),
  "component---src-templates-blog-posts-tsx": () => import("./../../../src/templates/blog-posts.tsx" /* webpackChunkName: "component---src-templates-blog-posts-tsx" */)
}

